<template lang="html">
  <PowerBiEmbed
    :report-id="report.id"
    type="dashboard"
    :config="config"
    @tile-clicked="onTileClicked"
  >
    <template #default="{print, displayFullscreen}">
      <EmbedHeader
        :name="report.name"
        @fullscreen="displayFullscreen"
        @back-to-workspace="$router.push({name: 'workspace'})"
      />
    </template>
  </PowerBiEmbed>
</template>

<script>
import PowerBiEmbed from '@components/power-bi/PowerBiEmbed'

import EmbedHeader from './components/EmbedHeader'

export default {
  components: {
    PowerBiEmbed,
    EmbedHeader
  },
  props: {
    report: Object
  },
  computed: {
    config () {
      return {
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false
        }
      }
    }
  },
  methods: {
    onTileClicked (event) {
      const {reportEmbedUrl, pageName} = event.detail

      const regex = /^https:\/\/.+reportId=([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}).*/
      const reportId = regex.exec(reportEmbedUrl)[1]

      this.$router.push({
        name: 'report',
        params: {
          ...this.$route.params,
          reportId
        },
        query: {
          pageName,
          fromDashboard: this.report.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
